*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.quiz-container {
    width: 800px;
    min-height: 500px;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 80px;
    padding: 30px 40px;
    
    
  }

.quiz-container ul .selected-answer {
    background-color:snow;
    border: 1px solid coral;
    border-radius: 4px;
    padding: 5px;
    font-size: medium;
  }

  .quiz-container .total-question {
    font-size: 16px;
    font-weight: 500;
    color: #e0dee3;
  }

  .quiz-container li{
    background-color:white;
    padding: 10px;
    border: 2px solid whitesmoke;
    margin-top: 10px;
    border-radius: 10px;
    font-size: medium;
    font-family:sans-serif;
  }

  .quiz-container .active-question-no {
    font-size: 25px;
    font-weight: 500;
    color:coral;
    padding-left: 20px;
  }

  element::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}


.background-modal{
    
    background-color:cornflowerblue;
    color: #11052c;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.liststyle{
    background-color:aliceblue;
    margin-bottom: 8px 8px 8px 8px;
    font-size: medium;
    border-radius: 10px;
}

 



  /* css file added */
  /* className={selectedAnswerIndex === index ? 'selected-answer' : null} */