.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* background-color: #fff; */
}

.content-container {
  flex: 1;
  overflow-y: auto;
}

.title {
  text-align: center;
  margin-top: 15px;
  font-weight: 600;
  color: rgb(92, 93, 97);
}

/* create test */

.cardformsheader {
  max-height: 15px;
  background-color: #5181cc;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-indent: -9999px;
}

.cardformsheadersection {
  max-height: 80px;
  background-color: #5181cc;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px;
}
.untitledForm {
  border-radius: 5px !important;
  margin-left: 35%;
  margin-right: 35%;
  margin-top: 3%;
  max-height: 18%;
}

.untitledBody {
  padding: 10px 25px 10px 25px;
}

.inputformfields {
  margin: 5px;
}

.questioncard {
  padding: 15px;
  /* background-color:whitesmoke; */
  max-height: 400px;
  min-height: 30%;
}

.sectionquestion {
  border-radius: 5px !important;
  margin-left: 35%;
  margin-right: 35%;
  margin-top: 3px;
  max-height: 65% !important;
}

.question {
  margin-bottom: 10%;
}

.buttonAddsection {
  background-color: #3a3630 !important;
  border-radius: 66px !important;
  font-weight: 900 !important;
  letter-spacing: 1px !important;
}

.buttonAddsection:hover {
  background-color: #feba30 !important;
  color: #3a3630 !important;
}

.buttonAddquestion {
  background-color: #3a3630 !important;
  border-radius: 66px !important;
  font-weight: 900 !important;
  letter-spacing: 1px !important;
}

.buttonAddquestion:hover {
  background-color: #feba30 !important;
  color: #3a3630 !important;
}

.submitButton {
  background-color: #3a3630 !important;
  border-radius: 3px !important;
  font-weight: 900 !important;
  letter-spacing: 1px !important;
}

.submitButton:hover {
  background-color: #feba30 !important;
  color: #3a3630 !important;
}

.headingLogin {
  font-family: "Poppins", sans-serif !important;
  font-weight: 900 !important;
  font-size: 27px !important;
  margin-top: 5px !important;
}

.OptionAddIcon {
  /* background-color: #5181CC !important;
  color: white !important; */
  min-height: 8px !important;
  max-height: 28px !important;
  width: 32px !important;
}

.borderOptions {
  /* border: 1px solid rgb(168, 168, 168);
  border-radius: 10px; */
  margin-bottom: 10px;
}

.minutesfield {
  padding: 0% 0% 0% 10px !important;
  border-radius: 3px !important;
  margin: 0% 2% 0% 9% !important;
}

.secondsfield {
  border-radius: 3px !important;
  margin: 0% 3% 0% 2% !important;
}

.customFont {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
}

.addSectionButton {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  margin-top: 10px;
  float: right;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  border: none;
}

.testTimer {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  margin-top: 10px;
  margin-right: 10px;
  float: right;
  background-color: none;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

.formsBackground {
  background-color: #ebebeb;
  /* background-image: url("./formsImages/abstract-gray-mosaic-polygon-surfaced-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}

.blankformBackground {
  /* padding-top: 5vh;
  background-color: whitesmoke;
  opacity: 0.9; */
  padding-top: 1vh;
  background-image: url("./formsImages/abstract-gray-mosaic-polygon-surfaced-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.blankformBackgrounduser {
  padding-top: 5vh;
  /* background-image: url("./formsImages/abstract-gray-mosaic-polygon-surfaced-background.jpg"); */
  background-color: #cfcecc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.cardForms {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.requestButton {
  background-color: #5181cc;
  color: white;
  padding: 13px 35px 12px 35px;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  margin-top: 3px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.requestButton:hover {
  background-color: #5181cc;
  color: #e7eef0;
}

.reqnotification-dot {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10;
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
}

.modal-access {
  border-radius: -28.375rem !important;
}

.lockIconBackground {
  background-color: #007bff !important;
  color: white;
  border-radius: 25px;
  padding: 3px;
  margin: 7px 2px 0px 5px;
}

.AccessBorder {
  border: 1px solid #cccccc;
  padding: 2px;
}

/* accept and deny in notification bar for private inductions */
.test-AcceptIcon {
  background-color: #2eb835;
  color: white;
  padding: 2px 5px 2px 5px;
  border: 1px solid #43a047;
  font-weight: bolder;
  margin-right: 15px;
  border-radius: 5px;
  width: 60px;
  height: 30px;
  cursor: pointer;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.test-AcceptIcon:hover {
  background-color: #00cc00;
  color: white;
  border: 1px solid #00cc00;
}

.test-declineIcon {
  background-color: white;
  color: #e95252;
  padding: 2px 5px 2px 5px;
  border: 1px solid #e95252;
  font-weight: bolder;
  border-radius: 5px;
  width: 60px;
  height: 30px;
  cursor: pointer;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.test-declineIcon:hover {
  background-color: #d80000;
  color: white;
}

.backgroundLock {
  background-color: #007bff;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  padding: 4px;
}

.blur-card-container {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 20px;
  backdrop-filter: blur(10px);
}
.d-flexes {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.custom-card {
  width: 100%;
  max-width: 300px;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 5px;
}
/* Add media queries for responsive design */
@media only screen and (min-width: 768px) {
  .custom-card {
    width: 30%;
    /* Adjust for larger screens */
  }
}
@media only screen and (min-width: 992px) {
  .custom-card {
    width: 24%;
    /* Further adjust for larger screens */
  }
}

.forms-heading {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  margin-left: 15px;
  letter-spacing: 1px;
}

.css-1abqjyq-MuiBadge-badge {
  background-color: darkorange !important;
}

/* removing menu shadow */
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: rgba(96, 96, 96, 0.24) 0px 2px 5px !important;
}

.ViewDelete {
  font-family: "poppins";
  font-weight: 600 !important;
  font-size: 16px !important;
  color: gray !important;
}

.AddPlusCircle {
  font-family: "poppins";
  font-weight: 600 !important;
  font-size: 16px !important;
  color: gray !important;
}

/* test screen UI */
.question-card {
  background-color: #e2e0db;
  width: 65%;
  float: left;
  min-height: 50vh;
  height: 100vh;
  padding: 8vh 10vh 10vh 10vh;
}

.section-card {
  /* width: 90%; */
  margin-top: 3%;
  padding: 2vh 2vh 2vh 2vh;
  background-color: whitesmoke;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 20%; */
}

.borderCard {
  margin: 5% !important;
}

@media (max-width: 1400px) {
  .borderCard {
    margin-left: 5% !important;
    margin-right: 15% !important;
    margin-bottom: 10% !important;
  }
  .inner-card {
    margin-left: 8% !important;
    margin-right: 10%;
    /* padding: 1px; */
  }
  .question-card {
    height: 110vh;
  }
  .total-card {
    height: 110vh;
  }
}

.inner-card {
  /* min-width: 100%;
  max-width:  100% ;
  min-height: 80%;
  max-height: 80%; */
  background-color: #ffffff;
  padding: 15px;
  justify-content: center;
  height: -webkit-fill-available;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  /* margin-left: 20%; */
  /* border-radius: 2%; */
}

/* Responsive styles for smaller screens */
@media (max-width: 868px) {
  .question-card {
    width: 100%;
    float: none;
    min-height: 50vh;
    height: 100vh;
    padding: 4vh 5vh 5vh 5vh;
  }

  .section-card {
    padding: 10vh 3vh 3vh 3vh;
  }

  .inner-card {
    padding: 20px;
  }
}

.question-container {
  margin-top: 20px;
}

h3 {
  font-size: 18px;
  color: #333;
}

.section {
  margin-left: 10px;
  cursor: pointer;
  background-color: darkgrey;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
}

.section:hover {
  background-color: #feba30;
  color: #ffffff;
}

/* .active {
  background-color: #feba30;
  color: #ffffff;
} */

.section.active {
  background-color: #feba30;
  color: white;
  padding: 9px 15px;
}

/* button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
} */

.nextButton {
  border-radius: 5px;
  padding: 7px 20px 7px 20px;
  margin: auto;
  float: right;
  background-color: #2baf32;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: white;
}

.nextButton:hover {
  background-color: #24a02a;
}

.skipButton {
  border-radius: 5px;
  padding: 7px 20px 7px 20px;
  margin: auto;
  background-color: #feba30;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: none;
}
.skipButton:hover {
  background-color: #ffab03;
  color: white;
}

.previousButton {
  border-radius: 5px;
  padding: 7px 20px 7px 20px;
  margin: auto;
  color: white;
  background-color: #ff9a9a;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

.previousButton:disabled {
  background-color: #e7c7c7;
}

.previousButton:not(:disabled):hover {
  background-color: #f77a7a;
  color: white;
}

.test-container {
  /* width: 800px; */
  min-height: 500px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 80px;
  padding: 10px 20px;
}

.test-container ul .selected-answer {
  background-color: snow;
  border: 1px solid coral;
  border-radius: 4px;
  padding: 8px;
  font-size: medium;
}

.test-container ul .marked-answer {
  background-color: rgb(252, 223, 205);
  border: 1px solid coral;
  border-radius: 4px;
  padding: 8px;
  font-size: medium;
}

.test-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.test-container li {
  background-color: white;
  padding: 10px;
  border: 2px solid rgb(218, 224, 230);
  margin-top: 25px;
  border-radius: 10px;
  font-size: medium;
  font-family: sans-serif;
}

.test-container .active-question-no {
  font-size: 25px;
  font-weight: 500;
  color: coral;
  padding-left: 20px;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* button:hover {
  background-color: #45a049;
} */

.navigation-buttons {
  margin-top: 20px;
}

.navigation-buttons button {
  margin-right: 10px;
}

.navigation-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.total-questions {
  display: flex;
  justify-content: center;
  margin-top: 40%;
}

.total-card {
  background-color: #cfcecc;
  width: 35%;
  float: left;
  height: 100vh;
}

.user-details-card {
  background-color: #e2e0de;
  width: 450px;
  position: fixed;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.usertext {
  font-size: 14px;
  font-weight: 500;
}

/* Responsive styles for smaller screens */
@media (max-width: 868px) {
  .total-card {
    background-color: #cfcecc;
    width: 100%;
    position: relative;
    padding-right: 9%;
    max-height: 50vh;
  }

  .pagination-container {
    flex-direction: column;
    align-items: center;
  }

  .total-questions {
    padding: 7vh 12vh 0vh 15vh;
  }

  .page-item {
    margin: 5px 0;
  }

  .f-flex {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
}

.page-link {
  color: #3498db;
  border: 1px solid #3498db;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.page-link:hover {
  background-color: #3498db;
  color: #ffffff;
}

.page-item:last-child {
  margin-right: 0;
}

.f-flex {
  display: flex !important;
  direction: inherit;
  text-wrap: nowrap;
  place-content: space-around;
  margin-right: 25px;
}

.answered-status {
  width: 5px;
  height: 5px;
  /* margin-right: -31px; */
  display: flex;
  align-items: center;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 17px solid #ff5733;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4caf50;
}

.row-test {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.col-test {
  text-align: center;
}

.square {
  width: 35px;
  height: 35px;
  opacity: 0.9;
  background-color: #2eb835;
  display: inline-block;
  border-radius: 66px;
}

.quesactive {
  width: 35px;
  height: 35px;
  background-color: #ff6a59;
  display: inline-block;
  border-radius: 5px;
}

.notvisited {
  width: 35px;
  height: 35px;
  background-color: white;
  border: 1px solid gainsboro;
  display: inline-block;
  border-radius: 5px;
}

.dot {
  width: 35px;
  height: 35px;
  background-color: #feba30;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.parallelogram {
  width: 35px;
  height: 25px;
  transform: skew(20deg);
  background: #949292;
  display: inline-block;
}

/* test-pagination */
/* .pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;

  li {
    margin: 4px 4px;
    cursor: pointer;

    a {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      text-decoration: none;
      color: #333;
      background-color: #fff;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f2f2f2;
      }

      &.active {
        background-color: #007bff;
        color: #fff;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      a {
        pointer-events: none;
      }
    }
  }
} */

.pagination .page-item.active .page-link {
  background: linear-gradient(#feba30, #ff9a9a);
}

.page-item-prev {
  display: none;
}

.page-link {
  margin: 5px 3px 5px 3px;
}

/* .page-link{
  background-color: #00cc00;

} */

.page-item.attempted .page-link {
  background-color: #2eb835 !important;
  opacity: 0.7;
  color: #fff !important;
  border-radius: 5px !important;
  border-radius: 66px !important;
}

.page-item.unattempted .page-link {
  background-color: #feba30 !important;
  color: #fff !important;
  border-radius: 66px !important;
}

/* .page-item.active .page-link{

} */

.page-link.active-link,
.page-link.attempted-link {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.page-item.disabled .page-link {
  pointer-events: none !important;
  background-color: #ddd !important;
  color: #777 !important;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
}

.pagination-br {
  flex-basis: 100%;
  height: 0;
  content: "";
}

.container-test {
  background-color: whitesmoke;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* CSS */
.search-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  cursor: auto;
}

[type="search"] {
  outline-offset: 2px;
}

.rectangleTotal {
  height: 20px;
  width: 40px;
  background-color: lightgrey;
}

.rectangleAttempted {
  height: 20px;
  width: 40px;
  background-color: #feba30;
}

.table-container {
  margin: 20px;
}

.section-header {
  margin: 0 5px;
  padding: 10px;
  background-color: #ccc;
}

#tableHeader {
  background-color: #ccc;
  padding: 10px;
}

.tableBody {
  border: 1px solid lightslategray;
}

.circleSection {
  height: 20px;
  width: 20px;
  background-color: #e2e0de;
}

.exportfeature {
  padding: 10px 10px 5px 15px;
  height: 40px;
  width: 150px;
  color: white;
  border-radius: 66px;
  background-color: #3498db;
}

.exportfeature:hover {
  background-color: #007bff;
}

.linkcsv {
  color: white !important;
}

.buttonSubmit {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 7px 20px 7px 20px;
  border: none;
  font-weight: bolder;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  margin: 10px 16px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.buttonSubmit:hover {
  background-color: #2c6ed8;
}

.text-center {
  text-align: center !important;
}

.swal-modal .swal-text {
  text-align: center;
}

.removeOption {
  visibility: hidden;
}

.radiobutton {
  visibility: hidden;
}

/* debounce back */
.debounceDownload {
  background-color: #3d78d6;
  color: white;
  padding: 2px 5px 2px 5px;
  border: 1px solid #2c6ed8;
  font-weight: bolder;
  /* margin-right: 15px; */
  border-radius: 5px;
  width: 100px;
  height: 30px;
  cursor: pointer;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.debounceDownload:hover {
  background-color: #1862da;
  color: white;
  border: 1px solid #2c6ed8;
}
.sortFilterIcon {
  color: gray;
  cursor: pointer;
}

.disabledButton {
  border-radius: 5px;
  padding: 7px 20px 7px 20px;
  margin: auto;
  float: right;
  background-color: gray;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: white;
  cursor: not-allowed;
}

.questiontext {
  font-size: 16px;
  font-weight: 500;
  color: #3a3630;
}

.modal-send-test-link {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
}

.send-test-link-header {
  background-color: #1976d2 !important;
  opacity: 0.9 !important;
}

.custom-menu {
  position: absolute;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  color: #777;
}

.menu {
  position: relative;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1;
  font-weight: 600;
  font-size: 15px;
  font-family: "poppins";
}

.menu button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu button:hover {
  background-color: #f0f0f0;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(219, 213, 213, 0.7);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.goBackButton{
  background-color:#3a3630;
  color: white;
  border: none;
  padding: 5px 12px 5px 12px;
  border: none;
  font-weight: 600;
  border-radius: 66px;
  text-align: center;
  font-size: 14px;
  margin: 5px 6px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.goBackButton:hover{
  background-color:#feba30;
  color: white;
}
