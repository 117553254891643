.login {
    background-color: #3A3630;
    color: white;
    padding-left: 44%;
    padding-right: 44%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    border: none;
    font-weight: bolder;
    border-radius: 8px;
    text-align: center;
    font-size: 15px;
    font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.login:hover {
    background-color: #FEBA30;
    color: #3A3630;
}

.form-control:hover {
    border-color: #3A3630 !important;
}

.signupbutton {
    background-color: #3A3630;
    color: white;
    border: none;
    font-weight: bolder;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    font-size: 15px;
    font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.signupbutton:hover {
    background-color: #FEBA30;
    color: #3A3630;
}

@media(max-width : 1020px) {
    .bjsimage {
        width: 20rem !important;
    }
}

.backgroundgraylogin {
    background-color: #EDEBEB;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media(max-width : 1020px) {
    .backgroundgraylogin {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0%;
    }
}