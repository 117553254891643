
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  list-style: none;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 11px;
  border:white;
}

.page-link {
  display: inline-block;
  padding: 6px 12px;
  color: white;
  background-color: #fff;
  border: white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-link:hover {
  color: #f5f5f5 !important;
}

.active .page-link {
  color: #f5f5f5 !important;
}
