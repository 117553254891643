@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");

.submitButtonInduction {
  background-color: #3a3630;
  color: white;
  padding: 10px 35px 10px 35px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.submitButtonInduction:hover {
  background-color: #feba30;
  color: #3a3630;
}

.submitCheckButton {
  background-color: #feba30;
  color: white;
  padding: 10px 35px 10px 35px;
  border: none;
  font-weight: 700;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  margin-top: 3px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.submitCheckButton:hover {
  background-color: #32cd32;
  color: #e7eef0;
}

.allowButton {
  background-color: #3a3630;
  color: white;
  padding: 11px 35px 11px 35px;
  border: none;
  font-weight: bolder;
  border-radius: 8px;
  text-align: center;
  font-size: 15px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.allowButton:hover {
  background-color: #feba30;
  color: #3a3630;
}

.CreateInductionButton {
  background-color: #3a3630;
  color: white;
  padding: 10px 25px 10px 25px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.CreateInductionButton:hover {
  background-color: #feba30;
  color: #3a3630;
}

.AddSlide {
  background-color: #57db7a;
  color: white;
  padding: 10px 20px 10px 20px;
  margin-right: 10px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.AddSlide:hover {
  background-color: #32cd32;
  color: white;
}

.RemoveSlide {
  background-color: #e95252;
  color: white;
  padding: 10px 20px 10px 20px;
  margin-right: 10px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.RemoveSlide:hover {
  background-color: #d80000;
  color: white;
}

.viewall {
  background-color: #e95252;
  color: white;
  padding: 5px 15px 5px 15px;
  border: none;
  border-radius: 66px;
  text-align: center;
}

.viewall:hover {
  background-color: #d80000;
  color: white;
}

.viewButton {
  background-color: #3a3630;
  color: white;
  padding: 10px 20px 10px 20px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 2%;
}

.viewButton:hover {
  background-color: #feba30;
  color: #3a3630;
}

.editIcon {
  background-color: white;
  color: #758588;
  padding: 5px 5px 6px 5px;
  border: 1px solid #758588;
  font-weight: bolder;
  margin-right: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.editIcon:hover {
  background-color: #758588;
  color: white;
}

.deleteIcon {
  background-color: white;
  color: #e95252;
  padding: 4px 5px 3px 5px;
  border: 1px solid #d80000;
  font-weight: bolder;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.deleteIcon:hover {
  background-color: #d80000;
  color: white;
}

.yKuWF {
  background-color: #e95252 !important;
  color: white;
}

.sc-jIBmdK {
  padding: 5px;
}

.scrollable {
  overflow-y: scroll;
  scrollbar-width: thin;
  /* for Firefox */
  -ms-overflow-style: none;
  /* for IE 10+ */
  scrollbar-color: transparent;
  /* for Chrome, Edge, and Safari */
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  /* adjust the width as needed */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* adjust the color as needed */
}

.editButton {
  background-color: #5181cc;
  color: white;
  padding: 5px 15px 5px 15px;
  border: none;
  font-weight: bolder;
  border-radius: 8px;
}

.editButton:hover {
  background-color: blue;
  color: white;
}

.eyeButton {
  background-color: #3a3630;
  color: white;
  padding: 8px 11px 10px 10px;
  border: 1px solid #3a3630;
  font-weight: bolder;
  margin-right: 5px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.eyeButton:hover {
  background-color: #feba30;
  border: 1px solid #feba30;
  color: white;
}

.updateButton {
  background-color: #3a3630;
  color: white;
  padding: 10px 11px 10px 10px;
  border: 1px solid #3a3630;
  font-weight: bolder;
  margin-right: 5px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.updateButton:hover {
  background-color: #feba30;
  border: 1px solid #feba30;
  color: white;
}

.headerInduction {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

/* .activeSection{
    background-color: #FEBA30;
    border-radius: 10px;
    padding-left: 3px !important;
    margin-left: 25px;
    margin-right: 25px;
    color: #3A3630;
}

.activeSection:hover{
    color:gray;
} */

.cancelButton {
  background-color: #e95252;
  color: white;
  padding: 12px 37px 12px 37px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.cancelButton:hover {
  background-color: #d80000;
  color: white;
}

.signup {
  color: #feba30;
  font-weight: 600;
}

.signup:hover {
  color: gray;
}

.forgotpass {
  color: #feba30;
  font-weight: 600;
  float: right;
}

.forgotpass:hover {
  color: gray;
}

.loginsection {
  color: #feba30;
  font-weight: 600;
}

.loginsection:hover {
  color: gray;
}

/* search in tables */
/* .oBSQY{
    height: 38px !important;
    width: 300px !important;
    margin-bottom: 20px !important;
    border-radius: 25px !important;
    display: flex;
    justify-content: start;
    align-items: start;
    float:left;
} */

.searchIcon {
  background-color: #e7eef0 !important;
  color: gray !important;
  border: 1 !important;
  height: 54px !important;
}

.searchIcon:hover {
  background-color: #758588 !important;
  color: white !important;
}

.searchBar {
  border: 1 !important;
}

.tableHeading {
  font-size: 30px !important;
  font-weight: 600;
  /* margin-right: 33%; */
  color: "gray";
  margin-top: 1%;
  margin-bottom: 1%;
  color: gray;
  /* display: flex !important;
    text-align: left !important;
    justify-content: flex-start !important;
    align-items: flex-start !important; */
}

.caaqOD {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* background-color: #758588 !important; */
}

.replyButton {
  background-color: #57db7a;
  color: white;
  padding: 10px 25px 10px 25px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 12px;
  /* margin-top: 15% !important; */
}

.replyButton:hover {
  background-color: #32cd32;
  color: white;
}

/* reply for instructor view */
@media(min-width : 667px) {
  .replyButton {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

/* reply for instructor view */
@media(min-width : 667px) {
  .viewReplies {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.cardheader {
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* background: #1d1d1d;
  background: -webkit-linear-gradient(left, #000000, #3a3630);
  background: -moz-linear-gradient(left, #000000, #3a3630);
  background: -ms-linear-gradient(left, #000000, #3a3630);
  background: -o-linear-gradient(left, #000000, #3a3630);
  background: linear-gradient(to right, #000000, #3a3630); */
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(58, 54, 48, 1) 0%,
      rgba(25, 29, 29, 1) 100%);
}

.cardheaderupdate {
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* background: #1d1d1d;
  background: -webkit-linear-gradient(left, #000000, #3a3630);
  background: -moz-linear-gradient(left, #000000, #3a3630);
  background: -ms-linear-gradient(left, #000000, #3a3630);
  background: -o-linear-gradient(left, #000000, #3a3630);
  background: linear-gradient(to right, #000000, #3a3630); */
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(58, 54, 48, 1) 0%,
      rgba(25, 29, 29, 1) 100%);
}

.closeButton {
  background-color: #e95252;
  color: white;
  padding: 10px 25px 10px 25px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 12px;
}

.closeButton:hover {
  background-color: #d80000;
  color: white;
}

.replyQuery {
  background-color: #57db7a;
  color: white;
  padding: 12px 35px 12px 35px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 12px;
}

.replyQuery:hover {
  background-color: #32cd32;
  color: white;
}

.viewReplies {
  background-color: #3a3630;
  color: white;
  padding: 10px 20px 10px 20px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 14px;
}

.viewReplies:hover {
  background-color: #feba30;
  color: #3a3630;
}

/* removing butons one by one */
.Remove {
  background-color: #e95252;
  color: white;
  padding: 10px 10px 10px 10px;
  border: none;
  font-weight: bolder;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.Remove:hover {
  background-color: #d80000;
  color: white;
}

.AddButton {
  background-color: #feba30;
  color: white;
  padding: 10px 20px 10px 20px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.AddButton:hover {
  background-color: #3a3630;
  color: white;
}

.readmore {
  color: #007bff;
}

.readless {
  color: #007bff;
}

/* ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
} */

ul.images > li {
  position: relative;
  padding-top: 66%;
}

ul.images > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2;
  margin-bottom: 0% !important;
  height: 90%;
  width: 100%;
  object-fit: cover;
  border: 1px solid #3a3630;
}

.errorContainer {
  padding: 300px;
}

.queryContainer {
  padding: 100px;
}

.warningText {
  margin-top: 30px;
  font-size: 25px;
  /* font-family: fantasy; */
  font-weight: 600;
  color: #3a3630;
}

.modalWarningText {
  font-size: 19px;
  /* font-family: fantasy; */
  font-weight: 600;
  color: #3a3630;
}

.mainHeading {
  font-size: 26px;
  font-weight: 900;
  color: #000000;
}

.count {
  font-size: 50px;
  color: #feba30;
  font-weight: 900;
  text-shadow: 1px 2px #000;
}

.background {
  background-color: white;
  height: 150px;
  width: 150px;
  border-radius: 6%;
}

.cardheaderroles {
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(58, 54, 48, 1) 0%,
      rgba(25, 29, 29, 1) 100%);
}

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  /* -webkit-appearance: none;
  appearance:initial; */
  /* creating a custom design */
  width: 15px;
  height: 15px;
  border-radius: 0.15em;
  margin-right: 0.5em;
  margin-top: 1px;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
  vertical-align: text-top;
}

.p-datatable-header {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(58, 54, 48, 1) 0%,
      rgba(25, 29, 29, 1) 100%) !important;
}

.p-datatable .p-datatable-thead>tr>th {
  background: rgb(255, 255, 255) !important;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(58, 54, 48, 1) 0%,
      rgba(25, 29, 29, 1) 100%) !important;
  color: #e7eef0 !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #e7eef0 !important;
}

/* .p-datatable .p-column-header-content{
  display: flex !important;
  justify-content: center !important;
} */

.icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  background-color: #007bff;
  color: #e7eef0;
}

.textofinfo {
  color: #3a3630;
}

.popoverSection {
  padding: 6px;
}

.form-control[type="file"] {
  padding-left: 8px !important;
}

ul {
  list-style-type: circle;
}

.AddNewSubTopic {
  background-color: #feba30;
  color: #3a3630;
  padding: 10px 20px 10px 20px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.AddNewSubTopic:hover {
  background-color: #3a3630;
  color: white;
}

/* removing butons one by one */
.RemoveSubTopic {
  margin-top: 30px;
  background-color: #e95252;
  color: white;
  padding: 10px 10px 10px 10px;
  border: none;
  font-weight: bolder;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.RemoveSubTopic:hover {
  background-color: #d80000;
  color: white;
}

.ViewAll {
  background-color: #3a3630;
  color: white;
  padding: 10px 20px 10px 20px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ViewAll:hover {
  background-color: #feba30;
  color: #3a3630;
}

.css-qbdosj-Input {
  margin: 8px !important;
  padding-bottom: 6px !important;
  padding-top: 3px !important;
}

.notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 20;
  width: 20px;
  height: 20px;
  background-color: #e95252;
  border-radius: 50%;
}

.header-right .notification_dropdown .nav-link {
  border-radius: 2.8375rem !important;
  padding: 1.205rem !important;
}

.backButton {
  height: 35px;
  width: 10px;
  border-radius: 50%;
}

.backButton:hover {
  background-color: #e95252;
  color: white;
}

.markAsRead {
  height: 35px;
  width: 140px;
  border-radius: 50%;
}

/* mark as read in notificationpanel */
@media(max-width : 1591px) {
  .read {
    padding-left: 36px;
  }

  .arrow {
    padding-top: 5px;
  }
}

/* navHeader-logo */
@media(max-width : 1020px) {
  .brand-logo img {
    width: 6rem !important;
  }
}

.markAsRead:hover {
  /* background-color: whitesmoke !important; */
  color: black !important;
  cursor: pointer;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  text-transform: capitalize;
}

.fc-today-button {
  text-transform: capitalize !important;
}

.css-1fdsijx-ValueContainer {
  padding: 1px 32px !important;
}

.onhoverfeature:hover {
  color: #e95252;
}

.deleteIconInductionList,
.downloadInduction,
.cancelInduction {
  background-color: #3a3630;
  color: white;
  padding: 10px 11px 10px 10px;
  border: 1px solid #3a3630;
  font-weight: bolder;
  margin-right: 5px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.cancelInduction {
  position: relative;
  background-color: #3a3630;
  border: 1px solid #3a3630;
  cursor: pointer;
}

.spinner-container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
}

.spinner-icon {
  font-size: 35px;
  color: #ffffff;
  position: absolute;
  left: 5px;
}

.cancel-icon {
  font-size: 25px;
  color: #ffffff;
  position: absolute;
  right: 13px;
}

.deleteIconInductionList:hover,
.downloadInduction:hover,
.cancelInduction:hover {
  background-color: #feba30;
  border: 1px solid #feba30;
  color: white;
}

.file-dropzone {
  border: 2px dashed #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-dropzone.active {
  background-color: #f0f0f0;
}

.buttonstyle {
  background-color: #3a3630;
  border: none;
}

.jodit-status-bar a.jodit-status-bar-link {
  display: none !important;
}

.select {
  width: 100% !important;
  min-height: 50px !important;
  min-width: 150px !important;
  border-radius: 5px !important;
  border-color: hsl(0, 0%, 80%) !important;
  padding: 10px !important;
}

/* accept and deny in notification bar for private inductions */
.AcceptIcon {
  background-color: white;
  color: #43a047;
  padding: 1px 3px 1px 3px;
  border: 1px solid #43a047;
  font-weight: bolder;
  margin-right: 5px;
  border-radius: 20px;
  width: 60px;
  height: 25px;
  cursor: pointer;
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.AcceptIcon:hover {
  background-color: #00cc00;
  color: white;
  border: 1px solid #00cc00;
}

.declineIcon {
  background-color: white;
  color: #e95252;
  padding: 1px 3px 1px 3px;
  border: 1px solid #e95252;
  font-weight: bolder;
  border-radius: 20px;
  width: 60px;
  height: 25px;
  cursor: pointer;
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.declineIcon:hover {
  background-color: #d80000;
  color: white;
}

.css-13cymwt-control {
  min-height: 50px !important;
}

/* pagination responsiveness for ipads */
@media (max-width: 1020px) {
  .pagination .page-item .page-link {
    padding: 7px 16px !important;
  }
}

/* pagination responsiveness for mobile devices */
@media (max-width: 667px) {
  .pagination .page-item .page-link {
    padding: 5px 8px !important;
  }
}

/* inductions animations */
.inductiondiv {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animatedInduction:hover {
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

/* removed background of notifications icon */
.widget-media .timeline .timeline-panel .media {
  background-color: transparent !important;
}

.ShowMore {
  /* background-color: gray; */
  color: #007bff;
  padding: 2px 5px 2px 7px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 11px;
}

.ShowMore:hover {
  /* background-color:#4CBC9A; */
  color: #3a3630;
}

.badge-success:hover {
  background-color: #00cc00 !important;
}

.badge-danger:hover {
  background-color: #e95252 !important;
}

.badge:hover {
  color: white !important;
}

.custombadge {
  line-height: 1;
  border-radius: 0.525rem;
  font-weight: 600;
  padding: 0.2rem 0.55rem;
  border: 0.0625rem solid transparent;
  cursor: default;
  font-size: 12px;
}

.custombadge-green {
  background-color: #c9edcc;
  color: #56c760;
}

.custombadge-green:hover {
  color: #56c760 !important;
}

.custombadge-red {
  background-color: #fcbbbc;
  color: #f75a5b;
}

.custombadge-red:hover {
  color: #f75a5b !important;
}

.setQuizTimer {
  background-color: #3a3630;
  color: white;
  padding: 10px 15px 10px 15px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.submits {
  background-color: #3a3630;
  color: white;
  padding: 10px 35px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  opacity: 1;
  /* Default opacity */
  transition: opacity 0.3s, background-color 0.3s;
  /* Add a smooth transition for opacity and background color change */
}

.submits:disabled {
  opacity: 0.5;
  /* Lower opacity when the button is disabled */
  cursor: not-allowed;
  /* Optional: Change cursor to not-allowed when the button is disabled */
}

.submits.enabled {
  opacity: 1;
  /* Increase opacity when the button is enabled */
}

.submits.enabled:hover {
  background-color: #feba30;
  /* Change to the desired color on hover when the button is enabled */
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3a3630;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #feba30;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 40%;
  font-size: 13px;
  font-weight: 700;
  font-family: Verdana, sans-serif;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 65%;
  font-size: 13px;
  font-weight: 700;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.p-column-filter-operator {
  display: none !important;
}

.p-column-filter-add-rule {
  display: none !important;
}

.p-datatable-scrollable-table > .p-datatable-thead {
  z-index: 0 !important;
}

.input[type="checkbox"] {
  z-index: 0 !important;
}

.form-check-input[type="checkbox"] {
  border-color: #d0d5d6 !important;
  cursor: pointer !important;
}

.form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  cursor: pointer !important;
}

/*  
scroll bar design */

.p-datatable-wrapper::-webkit-scrollbar {
  width: 6px;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: linear-gradient(180deg,
      rgb(118, 113, 113) 0%,
      rgba(58, 54, 48, 1) 0%,
      rgb(44, 46, 46) 100%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

/* grey out button in assign induction */

.greyoutbutton {
  opacity: 0.5;
  background-color: #feba30;
  color: white;
  padding: 10px 35px 10px 35px;
  border: none;
  font-weight: 700;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  margin-top: 3px;
  /* box-shadow: 1px 2px; */
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.fc-timeGridWeek-button {
  display: none !important;
}

.fc-timeGridDay-button {
  display: none !important;
}

.fc-dayGridMonth-button {
  text-transform: capitalize !important;
  cursor: default !important;
}

.MuiDialogActions-root {
  display: none !important;
}

.custome-accordion .accordion-button:after {
  background-color: #FF6A59 !important;
}

.form-check-input:disabled {
  background-color: rgb(230, 230, 230) !important;
  outline: #ccc !important;
  opacity: inherit !important;
  cursor: not-allowed !important;
}

.RFS-StepButton-d2.active {
  background-color: #FEBA30 !important;
}

.RFS-StepButton-d2.completed {
  background-color: rgb(91, 209, 100) !important;
}

.RFS-StepButton-d6.active {
  background-color: #FEBA30 !important;
}

.RFS-StepButton-d6.completed {
  background-color: rgb(91, 209, 100) !important;
}

.RFS-StepButton-d10.active {
  background-color: #FEBA30 !important;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.setQuizTimer.pulseAnimation {
  animation: pulseAnimation 2s infinite;
}

.clearButton {
  border: 1px solid #6366F1;
  color: #6366F1;
  padding: 6px 20px 6px 20px;
  margin-right: 10px;
  font-weight: bolder;
  border-radius: 6px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.clearButton:hover {
  background-color: white;
}

.applyButton {
  background-color: #6366F1;
  color: white;
  opacity: 0.8;
  padding: 6px 20px 6px 20px;
  margin-right: 10px;
  border: none;
  font-weight: bolder;
  border-radius: 6px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.applyButton:hover {
  background-color: #6366F1;
  color: white;
  opacity: 1;
}

.customModalHeader {
  background-color: #6366F1 !important;
}

.customModalContent {
  border: 1px #6366F1 solid !important;
  border-radius: 0px !important;
  border: none !important;
}

.cross-icon:hover {
  cursor: pointer;
}

.rdrInputRanges {
  display: none !important;
}

.buttonJoinDate {
  background-color: transparent;
  color: white;
  border: none;
  font-weight: 600;
}

.backgroundNotifyRead {
  background-color: #eaf4ff !important;
  border-radius: 4px;
}

.mediaBackground {
  background-color: #007bff !important;
  color: white;
  border-radius: 25px;
  padding: 3px;
  margin: 7px 10px 0px 5px;
  /* box-shadow: 2px 1px 1px #c9c7c5; */
}

.approvalStyle {
  background-color: #f1f8ff !important;
  color: white;
  border-radius: 25px;
  padding: 3px;
  margin: 7px 10px 0px 5px;
}

.inductionTabletext {
  color: dodgerblue;
}

.unadded-question {
  background-color: #edf3ff;
}

@media(min-width : 800px) {
  .textFilter {
    width: 13rem !important;
  }
}

.updatePublish {
  background-color: #1cc248;
  color: white;
  padding: 10px 20px 10px 20px;
  margin-right: 10px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.updatePublish:hover {
  background-color: #32cd32;
  color: white;
}

.previous {
  background-color: #e95252;
  color: white;
  padding: 10px 20px 10px 20px;
  margin-right: 10px;
  border: none;
  font-weight: bolder;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham SSm A", "Gotham SSm B", BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.previous:hover {
  background-color: #d80000;
  color: white;
}

.reorder {
  background-color: #1cc248;
  color: white;
  padding: 11px 25px 11px 25px;
  margin-right: 10px;
  border: none;
  font-weight: 600;
  border-radius: 66px;
  text-align: center;
  font-size: 15px;
  font-family: "poppins", sans-serif;
}

.reorder:hover {
  background-color: #32cd32;
  color: white;
}